import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _feae7578 = () => interopDefault(import('../src/pages/bad-access.vue' /* webpackChunkName: "pages/bad-access" */))
const _26fe8098 = () => interopDefault(import('../src/pages/booking/index.vue' /* webpackChunkName: "pages/booking/index" */))
const _7fb67c88 = () => interopDefault(import('../src/pages/colors.vue' /* webpackChunkName: "pages/colors" */))
const _66c61dfe = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _073e4aae = () => interopDefault(import('../src/pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _2c8cdf22 = () => interopDefault(import('../src/pages/notifications-guide.vue' /* webpackChunkName: "pages/notifications-guide" */))
const _de70996c = () => interopDefault(import('../src/pages/policy.vue' /* webpackChunkName: "pages/policy" */))
const _1747689c = () => interopDefault(import('../src/pages/requisites.vue' /* webpackChunkName: "pages/requisites" */))
const _3353817a = () => interopDefault(import('../src/pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _7dea1aae = () => interopDefault(import('../src/pages/support-chat.vue' /* webpackChunkName: "pages/support-chat" */))
const _21c44406 = () => interopDefault(import('../src/pages/account/bonuses/index.vue' /* webpackChunkName: "pages/account/bonuses/index" */))
const _57e4df42 = () => interopDefault(import('../src/pages/account/lottery/index.vue' /* webpackChunkName: "pages/account/lottery/index" */))
const _76b95b03 = () => interopDefault(import('../src/pages/account/notify.vue' /* webpackChunkName: "pages/account/notify" */))
const _53021db4 = () => interopDefault(import('../src/pages/account/operations.vue' /* webpackChunkName: "pages/account/operations" */))
const _2b6c0ec2 = () => interopDefault(import('../src/pages/account/orders.vue' /* webpackChunkName: "pages/account/orders" */))
const _e7ccd3d4 = () => interopDefault(import('../src/pages/account/passengers/index.vue' /* webpackChunkName: "pages/account/passengers/index" */))
const _54efd342 = () => interopDefault(import('../src/pages/account/profile.vue' /* webpackChunkName: "pages/account/profile" */))
const _ba3d04fe = () => interopDefault(import('../src/pages/account/requests/index.vue' /* webpackChunkName: "pages/account/requests/index" */))
const _3d719160 = () => interopDefault(import('../src/pages/account/tickets/index.vue' /* webpackChunkName: "pages/account/tickets/index" */))
const _3dd76920 = () => interopDefault(import('../src/pages/en/policy.vue' /* webpackChunkName: "pages/en/policy" */))
const _561a3468 = () => interopDefault(import('../src/pages/account/bonuses/operations.vue' /* webpackChunkName: "pages/account/bonuses/operations" */))
const _54e24a48 = () => interopDefault(import('../src/pages/account/lottery/enter.vue' /* webpackChunkName: "pages/account/lottery/enter" */))
const _1ecd7768 = () => interopDefault(import('../src/pages/account/passengers/create.vue' /* webpackChunkName: "pages/account/passengers/create" */))
const _3dfb0546 = () => interopDefault(import('../src/pages/account/requests/create.vue' /* webpackChunkName: "pages/account/requests/create" */))
const _244bc54e = () => interopDefault(import('../src/pages/content/about/us.vue' /* webpackChunkName: "pages/content/about/us" */))
const _02f54dea = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _47bbb404 = () => interopDefault(import('../src/pages/account/passengers/_id.vue' /* webpackChunkName: "pages/account/passengers/_id" */))
const _6802ec29 = () => interopDefault(import('../src/pages/account/requests/_id.vue' /* webpackChunkName: "pages/account/requests/_id" */))
const _568502ae = () => interopDefault(import('../src/pages/bid-return/create/_id.vue' /* webpackChunkName: "pages/bid-return/create/_id" */))
const _9678867a = () => interopDefault(import('../src/pages/bid-return/_id.vue' /* webpackChunkName: "pages/bid-return/_id" */))
const _68e31f1c = () => interopDefault(import('../src/pages/booking/_id.vue' /* webpackChunkName: "pages/booking/_id" */))
const _6cce6ed4 = () => interopDefault(import('../src/pages/order/_id/index.vue' /* webpackChunkName: "pages/order/_id/index" */))
const _1870d9b0 = () => interopDefault(import('../src/pages/search/_uid.vue' /* webpackChunkName: "pages/search/_uid" */))
const _57b0ce2e = () => interopDefault(import('../src/pages/order/_id/refund/index.vue' /* webpackChunkName: "pages/order/_id/refund/index" */))
const _2599afb7 = () => interopDefault(import('../src/pages/order/_id/refund/voluntary.vue' /* webpackChunkName: "pages/order/_id/refund/voluntary" */))
const _0b0955bb = () => interopDefault(import('../src/pages/news/_.vue' /* webpackChunkName: "pages/news/_" */))
const _23495212 = () => interopDefault(import('../src/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bad-access",
    component: _feae7578,
    meta: {"name":"bad-access-page","layout":"default"},
    name: "bad-access"
  }, {
    path: "/booking",
    component: _26fe8098,
    meta: {"name":"booking-index"},
    name: "booking"
  }, {
    path: "/colors",
    component: _7fb67c88,
    meta: {"name":"colors-page","layout":"default"},
    name: "colors"
  }, {
    path: "/login",
    component: _66c61dfe,
    meta: {"name":"login-page","layout":"default"},
    name: "login"
  }, {
    path: "/news",
    component: _073e4aae,
    meta: {"name":"news-list-page","layout":"default"},
    name: "news"
  }, {
    path: "/notifications-guide",
    component: _2c8cdf22,
    meta: {"name":"about-page","layout":"default"},
    name: "notifications-guide"
  }, {
    path: "/policy",
    component: _de70996c,
    meta: {"name":"policy-page","layout":"default"},
    name: "policy"
  }, {
    path: "/requisites",
    component: _1747689c,
    meta: {"name":"headless-page","layout":"default"},
    name: "requisites"
  }, {
    path: "/search",
    component: _3353817a,
    meta: {"name":"search-index"},
    name: "search"
  }, {
    path: "/support-chat",
    component: _7dea1aae,
    meta: {"name":"support-chat","layout":"default"},
    name: "support-chat"
  }, {
    path: "/account/bonuses",
    component: _21c44406,
    meta: {"name":"bonuses-page","layout":"default"},
    name: "account-bonuses"
  }, {
    path: "/account/lottery",
    component: _57e4df42,
    meta: {"name":"lottery-page","layout":"default"},
    name: "account-lottery"
  }, {
    path: "/account/notify",
    component: _76b95b03,
    meta: {"name":"notify-page","layout":"default","authRequired":true},
    name: "account-notify"
  }, {
    path: "/account/operations",
    component: _53021db4,
    meta: {"name":"operations-page","layout":"default"},
    name: "account-operations"
  }, {
    path: "/account/orders",
    component: _2b6c0ec2,
    meta: {"name":"orders-page","layout":"default","authRequired":true},
    name: "account-orders"
  }, {
    path: "/account/passengers",
    component: _e7ccd3d4,
    meta: {"name":"passengers-page","layout":"default","auth":true,"authRequired":true},
    name: "account-passengers"
  }, {
    path: "/account/profile",
    component: _54efd342,
    meta: {"name":"profile-page","layout":"default","authRequired":true},
    name: "account-profile"
  }, {
    path: "/account/requests",
    component: _ba3d04fe,
    meta: {"name":"account-requests","layout":"default","authRequired":true},
    name: "account-requests"
  }, {
    path: "/account/tickets",
    component: _3d719160,
    meta: {"name":"account-tickets","layout":"default","authRequired":true},
    name: "account-tickets"
  }, {
    path: "/en/policy",
    component: _3dd76920,
    meta: {"name":"policy-page","layout":"default"},
    name: "en-policy"
  }, {
    path: "/account/bonuses/operations",
    component: _561a3468,
    meta: {"name":"operations-page","layout":"default"},
    name: "account-bonuses-operations"
  }, {
    path: "/account/lottery/enter",
    component: _54e24a48,
    meta: {"name":"lottery-enter","layout":"default"},
    name: "account-lottery-enter"
  }, {
    path: "/account/passengers/create",
    component: _1ecd7768,
    meta: {"name":"passengers-create-page","layout":"default","authRequired":true},
    name: "account-passengers-create"
  }, {
    path: "/account/requests/create",
    component: _3dfb0546,
    meta: {"name":"account-requests-create","layout":"default","authRequired":true},
    name: "account-requests-create"
  }, {
    path: "/content/about/us",
    component: _244bc54e,
    meta: {"name":"about-page","layout":"default","head":{"meta":[{"hid":"description","name":"description","content":"История становления компании Razlet.KG"}]}},
    name: "content-about-us"
  }, {
    path: "/",
    component: _02f54dea,
    meta: {"name":"index-page","layout":"default"},
    name: "index"
  }, {
    path: "/account/passengers/:id",
    component: _47bbb404,
    meta: {"name":"passengers-update-page","layout":"default","authRequired":true},
    name: "account-passengers-id"
  }, {
    path: "/account/requests/:id",
    component: _6802ec29,
    meta: {"name":"account-requests-info","layout":"default","authRequired":true},
    name: "account-requests-id"
  }, {
    path: "/bid-return/create/:id?",
    component: _568502ae,
    meta: {"name":"bid-return","layout":"default"},
    name: "bid-return-create-id"
  }, {
    path: "/bid-return/:id?",
    component: _9678867a,
    meta: {"name":"bid-return","layout":"default"},
    name: "bid-return-id"
  }, {
    path: "/booking/:id",
    component: _68e31f1c,
    meta: {"name":"booking-id","layout":"default"},
    name: "booking-id"
  }, {
    path: "/order/:id",
    component: _6cce6ed4,
    meta: {"name":"order-id","layout":"default"},
    name: "order-id"
  }, {
    path: "/search/:uid",
    component: _1870d9b0,
    meta: {"name":"search-id","layout":"default"},
    name: "search-uid"
  }, {
    path: "/order/:id?/refund",
    component: _57b0ce2e,
    meta: {"name":"refund-page","layout":"default"},
    name: "order-id-refund"
  }, {
    path: "/order/:id?/refund/voluntary",
    component: _2599afb7,
    meta: {"name":"voluntary-refund-page","layout":"default"},
    name: "order-id-refund-voluntary"
  }, {
    path: "/news/*",
    component: _0b0955bb,
    meta: {"name":"news-page","layout":"default"},
    name: "news-all"
  }, {
    path: "/*",
    component: _23495212,
    meta: {"name":"headless-page","layout":"default"},
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
